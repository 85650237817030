<template>
    <figure :class="CSSClasses" class="image-hero">
        <Image class="image" v-bind="image" />
        <figcaption v-if="isDekNotEmpty(image.caption)" class="caption hero">
            <RichTextElements :elements="image.caption" bodyStyle="caption" />
        </figcaption>
        <figcaption v-else-if="!noCaption" class="caption hero">
            <Typography variant="body-display-micro">{{
                $t('sourceGetty', 1, {
                    locale: pageLanguage,
                })
            }}</Typography>
        </figcaption>
    </figure>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Object,
        required: true,
    },
    variant: {
        type: String,
        default: 'tall',
        validator: (value) => {
            return ['short', 'tall'].includes(value);
        },
    },
    noCaption: {
        type: Boolean,
        default: true,
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});

const CSSClasses = computed(() => [`is-${props.variant}`]);
</script>

<style lang="scss" scoped>
.image {
    height: 60rem;
    object-fit: cover;
    width: 100%;

    .is-short & {
        height: 30rem;
    }
    @include media-query(phone) {
        height: 40rem;
    }
}
.caption.hero {
    float: right;
    margin-right: 5%;
    margin-top: 15px;
}
</style>
